import LoginPerson from "./LoginPerson.png";
import SignupPerson from "./SignupPerson.png";
import BuisnessLoginPerson from "./BuisnessLoginPerson.png";
import LoginModal from "./LoginModal.png";
import LoginModal2 from "./LoginModal2.png";
const AuthImages = {
  LoginPerson,
  SignupPerson,
  BuisnessLoginPerson,
  LoginModal,
  LoginModal2
};

export default AuthImages;
