import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navigation from "./routes/routes";
// import Footer from "./components/Footer/Footer";
import SideBar from "./components/SideBar/SideBar";
import SideBarWidth from "./components/SideBar/SideBarWidth";
import DashboardSidebar from "./components/SideBar/DashboardSidebar";
import "./styles/NewDashboardStyles/dashboard.css";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      {/* <div className="sidebar-flex">
        <SideBar />
        <SideBarWidth />
        <Navigation />
      </div> */}
      {/* <div className="new-recall-dashboard-flex"> */}
      {/* <DashboardSidebar/> */}
      <Navigation />
      {/* </div> */}
      {/* <Footer /> */}
    </BrowserRouter>
  );
}

export default App;
