import React from "react";
import "../../styles/NewDashboardStyles/contact.css";
import DashboardIcons from "../../assets/DashboardIcons";

const Contact = () => {
  const clients = [
    {
      feedback:
        "The Recall Team is very supportive in terms of user satisfaction 😁😁 ...",
      name: "Dhairya Marwah",
    },
    {
      feedback:
        "The Recall Team is very supportive in terms of user satisfaction 😁😁 ...",
      name: "Dhairya Marwah",
    },
    {
      feedback:
        "The Recall Team is very supportive in terms of user satisfaction 😁😁 ...",
      name: "Dhairya Marwah",
    },
    {
      feedback:
        "The Recall Team is very supportive in terms of user satisfaction 😁😁 ...",
      name: "Dhairya Marwah",
    },
    {
      feedback:
        "The Recall Team is very supportive in terms of user satisfaction 😁😁 ...",
      name: "Dhairya Marwah",
    },
  ];
  return (
    <div className="recall-updated-dashboard">
      <div className="recall-updated-dashboard-elements">
        <div className="new-dashboard-header">Contact Recall</div>
        <div className="recall-dashboard-container">
          <div className="recall-contact-container-top">
            <div className="recall-contact-container-top-left">
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-top-left-card | new-dashboard-white-card new-dashboard-card"
              >
                <div className="new-dashboard-card-header">
                  <div
                    className="new-dashboard-card-header-rect"
                    style={{ backgroundColor: "#BCE4F9" }}
                  ></div>
                  Fill the form below to Reach out to us
                </div>
                <div className="new-dashboard-contact-form">
                  <form action="">
                    <div className="new-dashboard-contact-form-input">
                      <p>Your Email Id</p>
                      <input
                        type="email"
                        placeholder="Enter your Email Id here..."
                      />
                    </div>
                    <div className="new-dashboard-contact-form-input">
                      <p> Your Name</p>
                      <input
                        type="text"
                        placeholder="Enter your Name  here..."
                      />
                    </div>
                    <div className="new-dashboard-contact-form-input">
                      <p>Your Message </p>
                      <textarea
                        type="text"
                        placeholder="Enter your Message here..."
                      />
                    </div>
                    <button>Submit Form</button>
                  </form>
                </div>
              </div>
            </div>
            <div className="recall-contact-container-top-right">
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-top-left-card | new-dashboard-white-card new-dashboard-card"
              >
                <div className="new-dashboard-card-header">
                  <div
                    className="new-dashboard-card-header-rect"
                    style={{ backgroundColor: "#C8BDFA" }}
                  ></div>
                  Contact Details
                </div>
                <div className="recall-support-img">
                  <img src={DashboardIcons.recallsupport} alt="" />
                </div>
                <div className="recall-call-details">
                  <div className="recall-call-details-item">
                    <img src={DashboardIcons.Call} alt="" />
                    <p>+91 7037484499</p>
                  </div>
                  <div className="recall-call-details-item">
                    <img src={DashboardIcons.mail} alt="" />
                    <p>recalluae@gmail.com</p>
                  </div>
                  <div className="recall-call-details-item | dotted-item">
                    <img src={DashboardIcons.address} alt="" />
                    <p>
                      Address
                      <span>
                        16th Street Avenue, United Arab Emirates Dubai, 248001
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="recall-contact-container-bottom">
            <div
              style={{ height: "100%" }}
              className="recall-dashboard-container-top-left-card | new-dashboard-white-card new-dashboard-card"
            >
              <div className="new-dashboard-card-header">
                <div
                  className="new-dashboard-card-header-rect"
                  style={{ backgroundColor: "#F5BF9E" }}
                ></div>
                What our Clients say About Us
              </div>
              <div className="client-feedbacks">
                {clients.map((client) => (
                  <div className="client-feedback">
                    <div className="client-feedback-text">
                      "{client.feedback}"
                    </div>
                    <div className="client-feedback-name">~ {client.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
