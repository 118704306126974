import Bulb from "./Bulb.svg";
import Cart from "./Cart.svg";
import Co2 from "./Co2.svg";
import Copy from "./Copy.svg";
import Electricity from "./Electricity.svg";
import Energy1 from "./Energy1.svg";
import Energy2 from "./Energy2.svg";
import Energy3 from "./Energy3.svg";
import History from "./History.svg";
import HistoryIcon from "./HistoryIcon.svg";
import Others from "./Others.svg";
import Recall from "./Recall.svg";
import Recallbadge from "./Recallbadge.png";
import Telegram from "./Telegram.svg";
import Whatsapp from "./Whatsapp.svg";
import Info from "./Info.svg";
import Menu from "./Menu.svg";
import Contact from "./Contact.png";
import Back from "./Back.svg";
import Edit from "./Edit.svg";
import BlackEdit from "./BlackEdit.svg";
import Location from "./Location.png";
import Badge1 from "./badge1.png";
import Apple2 from "./Apple2.svg";
import Playstore2 from "./Playstore2.svg";
import Play from "./Play.svg";
import LogoBg from "./LogoBg.svg";
import Locate from "./Locate.svg";
import CurrentLoc from "./CurrentLoc.svg";
import recallPin from "./recallPin.svg";
import Mark from "./Mark.svg";
import RecallCoin from "./RecallCoin.svg";
import StatsArrow from "./StatsArrow.svg";
import Read from "./Read.svg";
import recallsupport from "./recallsupport.png";
import Call from "./Call.svg";
import mail from "./mail.svg";
import address from "./address.svg";
import drag from "./drag.svg";

const DashboardIcons = {
  Bulb,
  Call,
  address,
  drag,
  mail,
  Cart,
  recallsupport,
  Co2,
  Mark,
  Read,
  recallPin,
  CurrentLoc,
  Apple2,
  StatsArrow,
  Playstore2,
  Copy,
  Play,
  Locate,
  Electricity,
  Energy1,
  Energy2,
  Energy3,
  History,
  Info,
  Others,
  Recall,
  Recallbadge,
  Telegram,
  Whatsapp,
  Menu,
  RecallCoin,
  Contact,
  Back,
  Edit,
  LogoBg,
  BlackEdit,
  Location,
  Badge1,
  HistoryIcon
};

export default DashboardIcons;
