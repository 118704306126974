import React from "react";
import "../../styles/NewDashboardStyles/profile.css";

const Profile = () => {
  return (
    <div className="recall-updated-dashboard">
      <div className="recall-updated-dashboard-elements">
        <div className="new-dashboard-header">Your Profile</div>
        <div className="recall-dashboard-container">
          <div className="recall-profile-container-top">
            <div className="recall-profile-container-top-left">
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-top-left-card | new-dashboard-white-card new-dashboard-card"
              ></div>
            </div>
            <div className="recall-profile-container-top-right">
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-top-left-card | new-dashboard-white-card new-dashboard-card"
              ></div>
            </div>
          </div>
          <div className="recall-profile-container-bottom">
            <div className="recall-profile-container-bottom-left">
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-top-left-card | new-dashboard-white-card new-dashboard-card"
              ></div>
            </div>
            <div className="recall-profile-container-bottom-right">
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-top-left-card | new-dashboard-white-card new-dashboard-card"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
