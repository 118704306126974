import Dashboard from "./Dashboard";
import Contact from "./Contact";
import Profile from "./Profile";
import Locations from "./Locations";
const DashboardPages = {
  Dashboard,
  Contact,
  Profile,
  Locations,
};

export default DashboardPages;
