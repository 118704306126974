import Logo from "./Logo.svg";
import FooterLogo from "./FooterLogo.svg";
import NavbarLogo from "./NavbarLogo.svg";
import ModalLogo from "./ModalLogo.svg";
import RecallGrey from "./RecallGrey.svg";
const brand = {
  Logo,
  FooterLogo,
  NavbarLogo,
  ModalLogo,
  RecallGrey
};

export default brand;
