import React from "react";
import { scroller } from "react-scroll";
import MobileNavbar from "../../components/Navbar/MobileNavbar";
import Navbar from "../../components/Navbar/Navbar";
import SideBarIcons from "../../assets/SideBarIcons";
import brand from "../../assets/brand";
import LandingPageImages from "../../assets/LandingPageImages";
import Footer from "../../components/Footer/Footer";

const SingleGuide = () => {
  const handleClick = (section) => {
    scroller.scrollTo(section, {
      smooth: true,
      offset: -100,
      duration: 1200,
    });
  };
  const guideData = {};
  return (
    <>
      <div className="laptop-nav">
        <Navbar handleClick={handleClick} />
      </div>
      <div className="mobile-nav">
        <MobileNavbar />
      </div>

      <div className="recall-guide-container">
        <div className="recall-guide-header">
          <div className="recall-guide-header-left">
            <div className="recall-guide-header-breadcrumb">
              <div className="recall-guide-header-breadcrumb-item">Guides</div>
              <img src={SideBarIcons.ItemArrow} alt="" />
              <div className="recall-guide-header-breadcrumb-item | item-2">
                What is Recall & how it initiate br Recycling?
              </div>
            </div>
            <div className="recall-guide-header-text">
              <img src={SideBarIcons.Guide} alt="" />
              What is Recall & how it initiate <br /> Recycling?
            </div>
          </div>
          <div className="recall-guide-header-right">
            <img src={brand.RecallGrey} alt="" />
          </div>
        </div>
        <div className="recall-guide-content">
          <div className="intro-subtitle">
            In this guide we will break down the importance of Recycling in our
            day to day lives and how Recall helps in doing so.
          </div>
          <div className="thumbnail-img">
            <img src={LandingPageImages.Guide} alt="" />
          </div>
          <div className="thumbnail-img-text">
            Inaccessible information isn’t usable. Tasks, notes, personal goals,
            work goals — this information needs to be easy to find, referenced
            in multiple places, and visualized whatever way helps you be more
            productive. Notion databases make this possible, but there’s always
            room for improvement. So we reimagined databases from the ground up,
            and now they’re more powerful than ever.
          </div>
          <div className="guide-points">
            <div className="guide-point">
              <div className="guide-point-title">1. What’s Recall?</div>
              <div className="guide-point-text">
                The Recall website and app is designed to make recycling easier
                and more convenient by providing users with information on
                recycling guidelines, access to nearby recycling bins and
                tracking their recycling efforts.You can download and install
                the app from your device's app store. Search for "Recall UAE,"
                and follow the prompts to download and install it on your
                smartphone or tablet.Yes, the recycling app is available for
                both iOS and Android devices. You can find it on the Apple App
                Store and Google Play Store.
                <br /> <br />
                The app offers features such as recycling guidelines for
                different materials, a search function to find nearby smart
                bins, tracking your recycling activities, educational resources
                about recycling and access to redeemable rewards points.
              </div>
            </div>
            <div className="guide-point">
              <div className="guide-point-title">1. What’s Recall?</div>
              <div className="guide-point-text">
                The Recall website and app is designed to make recycling easier
                and more convenient by providing users with information on
                recycling guidelines, access to nearby recycling bins and
                tracking their recycling efforts.You can download and install
                the app from your device's app store. Search for "Recall UAE,"
                and follow the prompts to download and install it on your
                smartphone or tablet.Yes, the recycling app is available for
                both iOS and Android devices. You can find it on the Apple App
                Store and Google Play Store.
                <br /> <br />
                The app offers features such as recycling guidelines for
                different materials, a search function to find nearby smart
                bins, tracking your recycling activities, educational resources
                about recycling and access to redeemable rewards points.
              </div>
            </div>
            <div className="guide-point">
              <div className="guide-point-title">1. What’s Recall?</div>
              <div className="guide-point-text">
                The Recall website and app is designed to make recycling easier
                and more convenient by providing users with information on
                recycling guidelines, access to nearby recycling bins and
                tracking their recycling efforts.You can download and install
                the app from your device's app store. Search for "Recall UAE,"
                and follow the prompts to download and install it on your
                smartphone or tablet.Yes, the recycling app is available for
                both iOS and Android devices. You can find it on the Apple App
                Store and Google Play Store.
                <br /> <br />
                The app offers features such as recycling guidelines for
                different materials, a search function to find nearby smart
                bins, tracking your recycling activities, educational resources
                about recycling and access to redeemable rewards points.
              </div>
            </div>
            <div className="guide-point">
              <div className="guide-point-title">1. What’s Recall?</div>
              <div className="guide-point-text">
                The Recall website and app is designed to make recycling easier
                and more convenient by providing users with information on
                recycling guidelines, access to nearby recycling bins and
                tracking their recycling efforts.You can download and install
                the app from your device's app store. Search for "Recall UAE,"
                and follow the prompts to download and install it on your
                smartphone or tablet.Yes, the recycling app is available for
                both iOS and Android devices. You can find it on the Apple App
                Store and Google Play Store.
                <br /> <br />
                The app offers features such as recycling guidelines for
                different materials, a search function to find nearby smart
                bins, tracking your recycling activities, educational resources
                about recycling and access to redeemable rewards points.
              </div>
            </div>
            <div className="guide-point">
              <div className="guide-point-title">1. What’s Recall?</div>
              <div className="guide-point-text">
                The Recall website and app is designed to make recycling easier
                and more convenient by providing users with information on
                recycling guidelines, access to nearby recycling bins and
                tracking their recycling efforts.You can download and install
                the app from your device's app store. Search for "Recall UAE,"
                and follow the prompts to download and install it on your
                smartphone or tablet.Yes, the recycling app is available for
                both iOS and Android devices. You can find it on the Apple App
                Store and Google Play Store.
                <br /> <br />
                The app offers features such as recycling guidelines for
                different materials, a search function to find nearby smart
                bins, tracking your recycling activities, educational resources
                about recycling and access to redeemable rewards points.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer handleClick={handleClick} />
    </>
  );
};

export default SingleGuide;
