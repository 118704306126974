import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import { scroller } from "react-scroll";
import MobileNavbar from "../../components/Navbar/MobileNavbar";
import "../../styles/NewDashboardStyles/guide.css";
import SideBarIcons from "../../assets/SideBarIcons";
import { useNavigate } from "react-router-dom";
import brand from "../../assets/brand";
import LandingPageImages from "../../assets/LandingPageImages";
import DashboardIcons from "../../assets/DashboardIcons";

const Guide = () => {
  const navigate = useNavigate();
  const handleClick = (section) => {
    scroller.scrollTo(section, {
      smooth: true,
      offset: -100,
      duration: 1200,
    });
  };
  const guides = [
    {
      id: 0,
      img: LandingPageImages.Guide,
      title: "What is Recall & how it initiate Recycling.",
      desc: "The Recall website and app is designed to make recycling easier and more convenient by providing users with information on recycling guidelines...",
      read: "8 min read",
    },
    {
      id: 1,
      img: LandingPageImages.Guide,
      title: "What is Recall & how it initiate Recycling.",
      desc: "The Recall website and app is designed to make recycling easier and more convenient by providing users with information on recycling guidelines...",
      read: "8 min read",
    },
    {
      id: 2,
      img: LandingPageImages.Guide,
      title: "What is Recall & how it initiate Recycling.",
      desc: "The Recall website and app is designed to make recycling easier and more convenient by providing users with information on recycling guidelines...",
      read: "8 min read",
    },

    {
      id: 3,
      img: LandingPageImages.Guide,
      title: "What is Recall & how it initiate Recycling.",
      desc: "The Recall website and app is designed to make recycling easier and more convenient by providing users with information on recycling guidelines...",
      read: "8 min read",
    },
  ];
  return (
    <>
      <div className="laptop-nav">
        <Navbar handleClick={handleClick} />
      </div>
      <div className="mobile-nav">
        <MobileNavbar />
      </div>

      <div className="recall-guide-container">
        <div className="recall-guide-header">
          <div className="recall-guide-header-left">
            <div className="recall-guide-header-breadcrumb">
              <div className="recall-guide-header-breadcrumb-item">
                Help & Support{" "}
              </div>
              <img src={SideBarIcons.ItemArrow} alt="" />
              <div className="recall-guide-header-breadcrumb-item | item-2">
                Guides
              </div>
            </div>
            <div className="recall-guide-header-text">
              <img src={SideBarIcons.Guide} alt="" />
              Recall Guide
            </div>
            <p>
              Learn More about Recall through our <br /> available guides
            </p>
          </div>
          <div className="recall-guide-header-right">
            <img src={brand.RecallGrey} alt="" />
          </div>
        </div>
        <div className="recall-guides">
          {guides.map((guide) => {
            return (
              <div
                className="recall-guide"
                onClick={() => {
                  navigate(`/single-guide/${guide.id}`);
                }}
              >
                <div className="recall-guide-img">
                  <img src={guide.img} alt="" />
                </div>
                <div className="recall-guide-text">
                  <div className="recall-guide-text-title">{guide.title}</div>
                  <div className="recall-guide-text-desc">{guide.desc}</div>
                  <div className="recall-guide-text-read">
                    {" "}
                    <img src={DashboardIcons.Read} alt="" />
                    {guide.read}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Guide;
