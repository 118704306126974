import React, { useState, useEffect } from "react";
import customMap from "./customMap.json";
import { scroller } from "react-scroll";
// import { usePosition } from "use-position";
import Navbar from "../../components/Navbar/Navbar";
import LandingPageImages from "../../assets/LandingPageImages";
import MobileNavbar from "../../components/Navbar/MobileNavbar";
import "./BinLocations.css";
const BinLocations = () => {
  const handleClick = (section) => {
    scroller.scrollTo(section, {
      smooth: true,
      offset: -100,
      duration: 1200,
    });
  };

  const binLocations = [
    { id: 1, name: "Expo 2020" },
    { id: 9, name: "Kite Beach" },
    { id: 17, name: "Global Village Mall" },
    { id: 2, name: "Blue Waters Island" },
    { id: 10, name: "JBR" },
    { id: 18, name: "International City" },
    { id: 3, name: "The Pointe" },
    { id: 11, name: "City Walk, Cocacola Arena" },
    { id: 19, name: "Festival City Mall" },
    { id: 4, name: "Downtown, Dubai Mall" },
    { id: 12, name: "Dubai Parks and Resorts" },
    { id: 20, name: "Dubai Creek harbour" },
    { id: 5, name: "Barsha" },
    { id: 13, name: "SZC Metro near MOTF" },
    { id: 21, name: "Mirdif City Center" },
    { id: 6, name: "Downtown, MBR Blvd" },
    { id: 14, name: "Dubai Investment Park" },
    { id: 22, name: "Silicon Oasis, Community Cntr" },
    { id: 7, name: "Nakheel Mall" },
    { id: 15, name: "Ibn Battuta Mall" },
    { id: 23, name: "Academic City" },
    { id: 8, name: "JLT" },
    { id: 16, name: "Dubai Motor City, Autodrome" },
  ];
  return (
    <>
      <div>
        <div className="laptop-nav">
          <Navbar handleClick={handleClick} />
        </div>
        <div className="mobile-nav">
          <MobileNavbar />
        </div>
      </div>
      <div className="binLoc">
        <img
          className="img-binloc"
          src={LandingPageImages.binlocation}
          alt="img"
        />
      </div>
      <div className="container">
        <div className="all-locations">
          <div className="bin-icon-all"></div>
          <h2 className="bin-name-all">
            All Locations
            (Coming Soon)
          </h2>
          <span style={{ marginTop: "10px", marginLeft:"14px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M29.6469 10.3844C28.9969 10.3594 28.3937 10.7312 28.1281 11.325C27.8594 11.9187 27.975 12.6125 28.425 13.0875L33.3406 18.4H2.39999C1.82186 18.3906 1.28749 18.6937 0.996863 19.1937C0.703113 19.6906 0.703113 20.3094 0.996863 20.8062C1.28749 21.3062 1.82186 21.6094 2.39999 21.6H33.3406L28.425 26.9125C28.025 27.3312 27.8844 27.9312 28.0531 28.4844C28.2219 29.0375 28.675 29.4562 29.2375 29.5812C29.8031 29.7062 30.3906 29.5156 30.775 29.0875L39.1781 20L30.775 10.9125C30.4875 10.5937 30.0781 10.4 29.6469 10.3844Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="bin-locations">
          {binLocations.map((location) => (
            <div key={location.id} className="bin-location">
              {/* <div className="bin-icon"></div> */}
              <span className="icon-id">
                <span>
                  <img
                    style={{ marginTop: "5px" }}
                    src={LandingPageImages.vactor}
                    alt="location"
                  />
                </span>
                <span className="bin-num">{location.id}</span>
                <span className="bin-name">{location.name}</span>
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BinLocations;
