import BuisnessLogin from "./Login/BuisnessLogin";
import BusinessSignup from "./Signup/BusinessSignup";
import IndividualLogin from "./Login/IndividualLogin";
import IndividualSignup from "./Signup/IndividualSignup";
const AuthenticationPages = {
  BuisnessLogin,
  BusinessSignup,
  IndividualLogin,
  IndividualSignup,
};

export default AuthenticationPages;
