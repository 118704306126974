import React from "react";
import DashboardIcons from "../../assets/DashboardIcons";
import Wave from "react-wavify";
import ReactApexChart from "react-apexcharts";
const Dashboard = () => {
  const options2 = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2018-09-19T00:00:00.000Z",
        "2018-09-19T01:30:00.000Z",
        "2018-09-19T02:30:00.000Z",
        "2018-09-19T03:30:00.000Z",
        "2018-09-19T04:30:00.000Z",
        "2018-09-19T05:30:00.000Z",
        "2018-09-19T06:30:00.000Z",
      ],
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const series2 = [
    {
      name: "series1",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    {
      name: "series2",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
  ];
  return (
    <div className="recall-updated-dashboard">
      <div className="recall-updated-dashboard-elements">
        <div className="new-dashboard-header">Recall Dashboard</div>
        <div className="recall-dashboard-container">
          <div className="recall-dashboard-container-top">
            <div className="recall-dashboard-container-top-left">
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-top-left-card | new-dashboard-white-card new-dashboard-card"
              >
                <div className="new-dashboard-card-header">
                  <div
                    className="new-dashboard-card-header-rect"
                    style={{ backgroundColor: "#BCE4F9" }}
                  ></div>
                  Your Account Stats
                </div>
                <div className="new-dashboard-account-stats">
                  <h1>Dhairya Marwah 👋</h1>
                  <div className="new-dashboard-account-stats-bar">
                    <div className="new-dashboard-account-stats-bar-item | active-stat-point">
                      <div className="new-dashboard-account-stats-bar-item-name">
                        Recall Points <img src={DashboardIcons.Info} alt="" />
                      </div>
                      <div className="new-dashboard-account-stats-bar-item-value">
                        <img src={DashboardIcons.RecallCoin} alt="" /> 1024
                      </div>
                    </div>
                    <div className="new-dashboard-account-stats-bar-item">
                      <div className="new-dashboard-account-stats-bar-item-name">
                        Redeem Points <img src={DashboardIcons.Info} alt="" />
                      </div>
                      <div
                        style={{
                          backgroundColor: "#BCE4F9",
                        }}
                        className="new-dashboard-account-stats-bar-item-value-card"
                      >
                        <img src={DashboardIcons.StatsArrow} alt="" />
                        Store
                      </div>
                    </div>
                    <div className="new-dashboard-account-stats-bar-item">
                      <div className="new-dashboard-account-stats-bar-item-name">
                        Earned Badges <img src={DashboardIcons.Info} alt="" />
                      </div>
                      <div
                        className="new-dashboard-account-stats-bar-item-value-card"
                        style={{
                          backgroundColor: "#c8bdfa",
                        }}
                      >
                        <img src={DashboardIcons.StatsArrow} alt="" />
                        Badges
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="recall-dashboard-container-top-right">
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-top-right-card | new-dashboard-blue-card new-dashboard-card"
              >
                <div
                  style={{ color: "#fff" }}
                  className="new-dashboard-card-header"
                >
                  <div
                    className="new-dashboard-card-header-rect"
                    style={{ backgroundColor: "#fff" }}
                  ></div>
                  Schedule Pickup
                </div>
                <div className="choose-pickup">
                  Choose a <br /> pickup option
                </div>
                <div className="card-buttton">
                  <button>Schedule Pickup</button>
                </div>
              </div>
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-top-right-card | new-dashboard-white-card new-dashboard-card"
              >
                <div className="new-dashboard-card-header">
                  <div
                    className="new-dashboard-card-header-rect"
                    style={{ backgroundColor: "#C8BDFA" }}
                  ></div>
                  Recall History
                </div>
                <div className="new-card-history">
                  <div className="history-content">
                    <div className="history-item">
                      <div className="history-item-date">
                        <div className="history-item-icon">
                          <img src={DashboardIcons.HistoryIcon} alt="" />
                        </div>
                        <div className="history-item-date-text">
                          31st August,
                          <br /> 2023
                        </div>
                      </div>
                      <div className="history-item-points">+230 Pts</div>
                    </div>
                    <div className="history-item">
                      <div className="history-item-date">
                        <div className="history-item-icon">
                          <img src={DashboardIcons.HistoryIcon} alt="" />
                        </div>
                        <div className="history-item-date-text">
                          31st August,
                          <br /> 2023
                        </div>
                      </div>
                      <div className="history-item-points">+230 Pts</div>
                    </div>
                    <div className="history-item">
                      <div className="history-item-date">
                        <div className="history-item-icon">
                          <img src={DashboardIcons.HistoryIcon} alt="" />
                        </div>
                        <div className="history-item-date-text">
                          31st August,
                          <br /> 2023
                        </div>
                      </div>
                      <div className="history-item-points">+230 Pts</div>
                    </div>
                  </div>
                  <div className="history-layer"></div>
                </div>
                <div className="card-buttton | card-button-blue">
                  <button>View More</button>
                </div>
              </div>
            </div>
          </div>
          <div className="recall-dashboard-container-bottom">
            <div className="recall-dashboard-container-bottom-left">
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-bottom-left-card | wave-card new-dashboard-white-card new-dashboard-card"
              >
                <div className="wave-card-content">
                  <div className="new-dashboard-card-header">
                    <div
                      className="new-dashboard-card-header-rect"
                      style={{ backgroundColor: "#BCE4F9" }}
                    ></div>
                    Overall Energy Saved
                  </div>
                </div>
                <div className="energy-saved-top-stats">
                  <div className="energy-saved-top-stats-item">
                    <img src={DashboardIcons.Bulb} alt="" />
                    <div className="energy-saved-top-stats-item-value">
                      133.4kWh
                    </div>
                    <div className="energy-saved-top-stats-item-name">
                      total electricity
                    </div>
                  </div>
                  <div className="energy-saved-top-stats-item">
                    <img src={DashboardIcons.Co2} alt="" />
                    <div className="energy-saved-top-stats-item-value">
                      133.4kWh
                    </div>
                    <div className="energy-saved-top-stats-item-name">
                      total electricity
                    </div>
                  </div>
                  <div className="energy-saved-top-stats-item">
                    <img src={DashboardIcons.Electricity} alt="" />
                    <div className="energy-saved-top-stats-item-value">
                      133.4kWh
                    </div>
                    <div className="energy-saved-top-stats-item-name">
                      total electricity
                    </div>
                  </div>
                </div>
                <div className="new-dashboard-react-wave">
                  <Wave
                    fill="#89cff0"
                    paused={false}
                    options={{
                      height: 20,
                      amplitude: window.innerWidth > 600 ? 49 : 20,
                      speed: 0.15,
                      points: 3,
                    }}
                  />
                </div>
                <div className="new-dashboard-energies">
                  <div className="new-dashboard-energy">
                    <img src={DashboardIcons.Energy1} alt="" />
                    <p>Environment</p>
                  </div>
                  <div className="new-dashboard-energy">
                    <img src={DashboardIcons.Energy2} alt="" />
                    <p>Recyle</p>
                  </div>
                  <div className="new-dashboard-energy">
                    <img src={DashboardIcons.Energy3} alt="" />
                    <p>Sustainabilty</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="recall-dashboard-container-bottom-right">
              <div
                style={{ height: "81%" }}
                className="recall-dashboard-container-bottom-right-card | new-dashboard-white-card new-dashboard-card analytical-card"
              >
                <div className="analytical-card-header">
                  <div className="new-dashboard-card-header">
                    <div
                      className="new-dashboard-card-header-rect"
                      style={{ backgroundColor: "#BCE4F9" }}
                    ></div>
                    Your Recall Analytics
                  </div>
                  <div className="analytics-select">
                    <select name="" id="" placeholder="Last 7 days">
                      <option value="">Last 7 days</option>
                      <option value="">Last 7 days</option>
                    </select>
                  </div>
                </div>
                <div className="analytics-card-scale">
                  <ReactApexChart
                    options={options2}
                    series={series2}
                    type="area"
                    height={330}
                  />
                </div>
              </div>
              <div
                style={{ height: "19%" }}
                className="recall-dashboard-container-bottom-right-card | new-dashboard-white-card new-dashboard-card"
              >
                <div className="new-dashboard-social-share-flex">
                  <div className="new-dashboard-card-header">
                    <div
                      className="new-dashboard-card-header-rect"
                      style={{ backgroundColor: "#BCE4F9" }}
                    ></div>
                    Share About Us
                  </div>
                  <div className="new-dashboard-social-share">
                    <img src={DashboardIcons.Whatsapp} alt="" />
                    <img src={DashboardIcons.Telegram} alt="" />
                    <img src={DashboardIcons.Copy} alt="" />
                    <img src={DashboardIcons.Others} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
