import React, { useCallback, useState } from "react";
import "../../styles/NewDashboardStyles/contact.css";
import DashboardIcons from "../../assets/DashboardIcons";
import { useDropzone } from "react-dropzone";

const Report = () => {
  const [fileNames, setFileNames] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    // Extract file names and update state
    const names = acceptedFiles.map((file) => file.name);
    setFileNames(names);

    // Do something with the dropped files (e.g., upload to server)
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="recall-updated-dashboard">
      <div className="recall-updated-dashboard-elements">
        <div className="new-dashboard-header">Report a bug ⚠️</div>
        <div className="recall-dashboard-container">
          <div className="recall-contact-container-top | recall-report-container-top">
            <div className="recall-contact-container-top-left">
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-top-left-card | new-dashboard-white-card new-dashboard-card"
              >
                <div className="new-dashboard-card-header">
                  <div
                    className="new-dashboard-card-header-rect"
                    style={{ backgroundColor: "#BCE4F9" }}
                  ></div>
                  Fill the form below to Submit a bug
                </div>
                <div className="new-dashboard-contact-form">
                  <form action="">
                    <div className="new-dashboard-contact-form-input">
                      <p>Your Email Id</p>
                      <input
                        type="email"
                        placeholder="Enter your Email Id here..."
                      />
                    </div>
                    <div className="new-dashboard-contact-form-input">
                      <p> Your Name</p>
                      <input
                        type="text"
                        placeholder="Enter your Name  here..."
                      />
                    </div>
                    <div className="new-dashboard-contact-form-input | report-text-area">
                      <p>Explain the bug you found</p>
                      <textarea
                        type="text"
                        placeholder="Explain the bug you found here..."
                      />
                    </div>
                    <div className="new-dashboard-contact-form-input">
                      <p>
                        Upload a Screenshot of the bug you found{" "}
                        <span>(Optional)</span>
                      </p>
                      <div
                        className="draganddrop"
                        {...getRootProps()}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "200px",
                          outline: "none",
                          cursor: "pointer",
                        }}
                      >
                        <input {...getInputProps()} />
                        <img src={DashboardIcons.drag} alt="" />
                        {isDragActive ? (
                          <p>Drop the files here...</p>
                        ) : (
                          <p>Drag 'n' drop an image here, or click to browse</p>
                        )}
                        {fileNames.length > 0 && (
                          <div>
                            <p>
                              Selected image:  {fileNames.map((name, index) => (
                                <>{name}</>
                              ))}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <button>Submit Bug</button>
                  </form>
                </div>
              </div>
            </div>
            <div className="recall-contact-container-top-right">
              <div
                style={{ height: "100%" }}
                className="recall-dashboard-container-top-left-card | new-dashboard-white-card new-dashboard-card"
              >
                <div className="new-dashboard-card-header">
                  <div
                    className="new-dashboard-card-header-rect"
                    style={{ backgroundColor: "#C8BDFA" }}
                  ></div>
                  Contact Details
                </div>
                <div className="recall-support-img">
                  <img src={DashboardIcons.recallsupport} alt="" />
                </div>
                <div className="recall-call-details">
                  <div className="recall-call-details-item">
                    <img src={DashboardIcons.Call} alt="" />
                    <p>+91 7037484499</p>
                  </div>
                  <div className="recall-call-details-item">
                    <img src={DashboardIcons.mail} alt="" />
                    <p>recalluae@gmail.com</p>
                  </div>
                  <div className="recall-call-details-item | dotted-item">
                    <img src={DashboardIcons.address} alt="" />
                    <p>
                      Address
                      <span>
                        16th Street Avenue, United Arab Emirates Dubai, 248001
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
