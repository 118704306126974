import React, { useState, useEffect } from "react";
import "../../styles/NewDashboardStyles/dashboardsidebar.css";
import SideBarIcons from "../../assets/SideBarIcons";
import brand from "../../assets/brand";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const DashboardSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setshow] = useState(true);
  const [open, setOpen] = useState(false);
  const sidebarData = {
    navigation: [
      {
        name: "Dashboard",
        icon: SideBarIcons.Home,
        whiteicon: SideBarIcons.WhiteHome,
        path: "/dashboard",
        nonvistible: false,
      },
      {
        name: "Recall Store",
        icon: SideBarIcons.Store,
        whiteicon: SideBarIcons.WhiteStore,
        nonvistible: true,
        link: "https://store.recalluae.com",
      },
      {
        name: "Bin Locations",
        icon: SideBarIcons.LocationIcon,
        whiteicon: SideBarIcons.LocationIcon,
        nonvistible: false,
        path: "/binlocations",
        // link: "https://recalluae.com/binlocations",
      },
      {
        name: "Contact Recall",
        icon: SideBarIcons.Contact,
        whiteicon: SideBarIcons.WhiteContact,
        nonvistible: false,
        path: "/contact",
      },
      {
        name: "Your Profile",
        icon: SideBarIcons.Profile,
        whiteicon: SideBarIcons.WhiteProfile,
        nonvistible: false,
        path: "/profile",
      },
    ],
    profile: [
      {
        name: "Report a Bug",
        icon: SideBarIcons.Report,
        whiteicon: SideBarIcons.ReportWhite,
        nonvistible: false,
        path: "/report",
      },
      {
        name: "Recall Guide",
        icon: SideBarIcons.Guide,
        whiteicon: SideBarIcons.Guide,
        nonvistible: false,
        path: "/guide",
      },
      {
        name: "Logout",
        icon: SideBarIcons.Logout,
        whiteicon: SideBarIcons.Logout,
        nonvistible: true,
        path: "",
      },
    ],
  };
  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/login/business" ||
      location.pathname === "/signup/individual" ||
      location.pathname === "/login/individual" ||
      location.pathname === "/signup/business" ||
      location.pathname === "/terms-of-use" ||
      location.pathname === "/privacy-policy" ||
      location.pathname === "/binlocations"
    ) {
      setshow(false);
    } else {
      setshow(true);
    }
  }, [location]);
  return (
    <>
    {show && (
    <div className="dashboard-sidebar">
      <div className="dashboard-sidebar-top">
        <div className="dashboard-sidebar-logo">
          <img src={brand.Logo} alt="" />
        </div>
        <div className="dashboard-sidebar-navigation | dashboard-sidebar-data">
          <p>Recall Navigation</p>
          <div className="dashboard-sidebar-navigation-list">
            {sidebarData.navigation.map((item, index) => {
              return (
                <div
                  className={`dashboard-sidebar-navigation-item ${
                    (location.pathname === item.path &&
                      "dashboard-sidebar-navigation-item-active") ||
                    ""
                  }`}
                  onMouseEnter={(e) => {
                    e.currentTarget.classList.add(
                      "dashboard-sidebar-navigation-item-hovered"
                    );
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.classList.remove(
                      "dashboard-sidebar-navigation-item-hovered"
                    );
                  }}
                  onClick={() => {
                    if (item.link) {
                      window.open(item.link, "_blank"); // Open link in new tab/window
                    } else {
                      navigate(item.path);
                    }
                  }}
                >
                  <div className="dashboard-sidebar-navigation-item-flex">
                    <div className="dashboard-sidebar-navigation-item-flex-left">
                      <div className="dashboard-sidebar-navigation-item-img">
                        <img
                          src={
                            location.pathname === item.path
                              ? item.whiteicon
                              : item.icon
                          }
                          alt=""
                        />
                      </div>
                      <div className="dashboard-sidebar-navigation-item-text">
                        {item.name}
                      </div>
                    </div>
                    <div className="dashboard-sidebar-navigation-item-flex-right">
                      <img src={SideBarIcons.ItemArrow} alt="" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="dashboard-sidebar-profile | dashboard-sidebar-navigation dashboard-sidebar-data">
          <p>Help & Support</p>
          <div className="dashboard-sidebar-profile-list">
            {sidebarData.profile.map((item, index) => {
              return (
                <div
                  className={`dashboard-sidebar-navigation-item ${
                    (location.pathname === item.path &&
                      "dashboard-sidebar-navigation-item-active") ||
                    ""
                  }`}
                  onMouseEnter={(e) => {
                    e.currentTarget.classList.add(
                      "dashboard-sidebar-navigation-item-hovered"
                    );
                  }}
                  onClick={() => {
                    if (item.nonvistible) {
                      setOpen(true);
                    } else {
                      navigate(item.path);
                    }
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.classList.remove(
                      "dashboard-sidebar-navigation-item-hovered"
                    );
                  }}
                >
                  <div className="dashboard-sidebar-navigation-item-flex">
                    <div className="dashboard-sidebar-navigation-item-flex-left">
                      <div className="dashboard-sidebar-navigation-item-img">
                        <img
                          src={
                            location.pathname === item.path
                              ? item.whiteicon
                              : item.icon
                          }
                          alt=""
                        />
                      </div>
                      <div className="dashboard-sidebar-navigation-item-text">
                        {item.name}
                      </div>
                    </div>
                    <div className="dashboard-sidebar-navigation-item-flex-right">
                      <img src={SideBarIcons.ItemArrow} alt="" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="dashboard-sidebar-bottom">
        <button>Download the Recall App</button>
      </div>
    </div>
    )}
    </>
  );
};

export default DashboardSidebar;
